import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import CovidART from './covidART'

export default function App(){
  return(
    <Router>            
        <Switch>
          <Route path="/covidART/declaration">
            <CovidART />
          </Route>
          <Route path="/covidART/">
            <h1>Success</h1>
          </Route>
        </Switch>      
    </Router>  
  )
}