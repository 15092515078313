import React from "react";
import {Container, Row, Col, Button, Image, Card, Form, Modal} from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment-timezone'
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css"


var tomorrow = moment().subtract(0, "day");

function valid(current) {
  return current.isBefore(tomorrow)
}

export default class CovidART extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            empId: '',
            fullName: '',
            phoneNumber: '',
            testDate: moment(new Date(),"YYYY-MMM-DD").format("YYYY-MMM-DD"),            
            testResult: '',
            consentData : false,
            testPhoto: null,                    
            show : false
        };

        this.handleChange = this.handleChange.bind(this);       
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.handleDateChange = this.handleDateChange.bind(this); 
        this.handleTimeChange = this.handleTimeChange.bind(this); 
        this.handlePhoto = this.handlePhoto.bind(this); 
        this.handleCheckbox = this.handleCheckbox.bind(this);               
        this.handleClose = this.handleClose.bind(this)
    }

    handleChange = e =>{                        
        this.setState({ [e.target.name]: e.target.value  })                
    }

    handleCheckbox = e =>{
        this.setState({ consentData : e.target.checked })        
    }

    handleDateChange = e => this.setState({ testDate : e }) 
    handleTimeChange = e => this.setState({ testTime : e }) 
    handlePhoto = e => this.setState({ testPhoto : e.target.files[0] }) 

    handleSubmit = e =>{                
        e.preventDefault();           
         
        let data = new FormData()
        data.append('testPhoto',this.state.testPhoto)
        data.append('empId', this.state.empId)
        data.append('fullName', this.state.fullName)
        data.append('phoneNumber', this.state.phoneNumber)
        data.append('testDate', moment(this.state.testDate,"YYYY-MMM-DD").format("YYYY-MMM-DD"))        
        data.append('testResult', this.state.testResult)
        data.append('consentData', this.state.consentData)
        
        axios({
            method : 'POST',            
            url : 'https://api.forms.mpolis.sg/',            
            data : data            
        })                
        // axios.post("https://api.forms.mpolis.sg", data)
        .then( res => {             
            if(res.data){
                this.setState({ show : true })
            }
        })             
        .catch( (err) => console.log(err))
                                
    }


    handleClose = () =>{
        this.setState({ empId:'', fullName:'', phoneNumber:'', testDate: moment(new Date(),"YYYY-MMM-DD").format("YYYY-MMM-DD"), consentData: false, testPhoto:null, show : false})        
        document.getElementById("C19").reset()
        window.close()
    }

    
    render() {     
        
        const isConsent = this.state.consentData    
        let submitBtn
        if(isConsent){
            submitBtn = <Button type="submit" variant="warning" style={{ marginTop: "20px"}} >Submit</Button>
        }
        else{
            submitBtn = <Button type="submit" variant="secondary" style={{ marginTop: "20px"}} disabled>Submit</Button>
        }
        return(            
        <Container>
            <Row style={{marginBottom : '20px'}}>            
                <Col md={{ span:6, offset:3}} className="text-center">
                    <Image src="/image/mss.png" width="250rem" rounded/> 
                    <h3>Metropolis Security Systems Pte Ltd</h3>
                </Col>                                
            </Row>
            <Row>
                <Col md={{ span:6, offset:3}}>
                    <Card md={12} style={{ marginBottom:'50px'}}>
                    <Card.Body>
                        <Card.Title className="text-center" style={{ marginBottom: '30px'}}><h4>Time Limited Workplace ART Kits Results Declaration</h4></Card.Title>
                        <Card.Text>
                            <Form id="C19" onSubmit={this.handleSubmit} enctype="multipart/form-data">
                                <Form.Group className="mb-3" controlId="fmgEmpID">
                                    <Form.Label><b>Employee ID</b></Form.Label>
                                    <Form.Control type="text" placeholder="Eg. M000027" value={this.state.empId} name="empId" onChange={this.handleChange} required/>                                    
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="fmgFullName">
                                    <Form.Label><b>Full Name</b></Form.Label>
                                    <Form.Control type="text" placeholder="E.g John Doe" value={this.state.fullName} name="fullName" onChange={this.handleChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="fmgMobileNumber">
                                    <Form.Label><b>Mobile Number</b></Form.Label>
                                    <Form.Control type="text" placeholder="E.g 8765 4321" value={this.state.phoneNumber} name="phoneNumber" onChange={this.handleChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="fmgTestDate">
                                    <Form.Label><b>Tested Date</b></Form.Label>
                                    <Datetime dateFormat="YYYY-MMM-DD" timeFormat={false} isValidDate={valid} value={this.state.testDate} name="testDate" onChange={this.handleDateChange} required/>
                                    {/* <Datetime testDate={this.state.testDate} onChange={this.handleDateChange}/> */}
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="fmgTestTime">
                                    <Form.Label><b>Tested Time</b></Form.Label>
                                    <Datetime dateFormat={false} timeFormat="hh:mm A" name="testTime" value={this.state.testTime} onChange={this.handleTimeChange}/>
                                    <Datetime testTime={this.state.testTime} onChange={this.handleChange}/>
                                </Form.Group> */}
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Label><b>Test Result</b></Form.Label>
                                    <Form.Check name="testResult" id="Negative" type="radio" label="Negative" value="Negative" onChange={this.handleChange} required/>
                                    <Form.Check name="testResult" id="Positive" type="radio" label="Positive" value="Positive" onChange={this.handleChange} required/>
                                </Form.Group>
                                <Form.Group controlId="formFileSm" className="mb-3">
                                    <Form.Label><b>Take Photo of your test result (See Guide Below)</b></Form.Label>
                                    <Form.Control type="file" size="sm" name="testPhoto" onChange={this.handlePhoto} required/>
                                    {/* <FilePond name="testPhoto" allowMultiple={false} server={null} instantUpload={false} onupdatefiles={this.handlePhoto}> </FilePond> */}

                                    {/* <FilePond files={this.state.testPhoto} name="testPhoto" allowMultiple={false} server={null} instantUpload={false} onupdatefiles={ (fileItems) =>{
                                        this.setState({
                                            testPhoto: fileItems.map( (fileItem) => fileItem.file)
                                        })
                                    } }> </FilePond> */}
                                </Form.Group>

                                <div className='text-center' style={{marginBottom : '20px'}}>
                                    <h5>Photo Taking Guide</h5>
                                    <Image className='img-fluid' src="/image/sampleTestResult2.png" width="400rem" rounded/> 
                                </div>

                                <Form.Group className="mb-3" controlId="formConsent">
                                    <Form.Check type="checkbox" name="consentData" checked={this.state.consentData} onClick={this.handleCheckbox} label="I have submitted this ART result is true and correct . I made it knowing that I will be liable to prosecution and administrative action if I have stated in it anything which I know to be false or do not believe to be true." />
                                </Form.Group>
                        
                                <div className="text-center">                                    
                                    {submitBtn}
                                </div>
                            </Form>
                        </Card.Text>                        
                    </Card.Body>
                    </Card>
                </Col>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Successfully submitted</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your data has been submitted Successfully!</Modal.Body>
                    <Modal.Footer>                 
                    <Button variant="primary" onClick={this.handleClose}>
                        Thank you!
                    </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
        </Container>     
      );
    }
  }